import { trimStart } from "lodash";
import React from "react";
import Link from "./link";
import { useI18next } from "gatsby-plugin-react-i18next";

interface BreadcrumbProps {
  items: {
    id: string;
    title: string;
    url: string;
  }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items = [] }) => {
  const parentItems = items.length >= 2 ? items.slice(0, items.length - 1) : [];
  const currentItem = items.length >= 1 ? items[items.length - 1] : null;
  const { t } = useI18next();

  return (
    <div className="breadcrumb">
      <div className="breadcrumb__content">
        {t("BREADCRUMB_YOU_ARE_HERE")}:
        {parentItems.map((breadcrumbItem) => (
          <div className="breadcrumb__item" key={`${breadcrumbItem.id}`}>
            <Link
              href={`/${trimStart(breadcrumbItem.url, "/")}`}
              internal
              title={breadcrumbItem.title}
              className="link link--plain"
            >
              {breadcrumbItem.title}
            </Link>
          </div>
        ))}
        {currentItem && (
          <div className="breadcrumb__item breadcrumb__item--active">{currentItem.title}</div>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
