import {
  TideItemConnection,
  TideItemForDestinations,
  TideItemForGeographicalRegionConnection,
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForNotificationComponentConnection,
  TideItemForPage,
  TideItemForSearchPage,
  TideItemForUspGroup,
  TideItemForWebsite,
} from "../types";
import { compact, first, isEmpty, kebabCase } from "lodash";

import Breadcrumb from "../components/breadcrumb";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { LookupQueryData } from "../components/qsm";
import Navbar from "../components/navbar";
import PageComponents from "../components/page-components";
import React from "react";
import StickyBar from "../components/sticky-bar";
import { graphql } from "gatsby";
import { getChildNavigationLinks } from "../utils";
import Link from "../components/link";
import Icon from "../components/icon";
import Seo from "../components/seo";
import NotificationBar from "../components/notification-bar";
import { AlternateRoute } from "../../.gatsby/gatsby-node";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface PageTemplateProps {
  data: PageData;
  pageContext: {
    id: string;
    language: string;
    parentIds: string[];
    alternateRoutes: AlternateRoute[];
  };
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data, pageContext }) => {
  const generalPageContent = data.page?.content?.general;
  const title = compact([generalPageContent?.titlePrefix, generalPageContent?.title]).join(" ");
  const notificationBar = first(data.notifications?.nodes);
  const { t } = useI18next();

  return (
    <Layout>
      <Seo
        title={title}
        seoTitle={data.page.content?.seo?.seoTitle ?? undefined}
        seoDescription={data.page.content?.seo?.seoDescription ?? undefined}
        seoKeywords={data.page.content?.seo?.seoKeywords ?? undefined}
      />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
          backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
        />
      )}
      <Navbar
        alternateRoutes={pageContext.alternateRoutes}
        mainNavigationSection={data.mainNavigationSection}
        fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
        fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        phone={data.website?.content?.contact?.phone ?? ""}
        destinations={data.navigationDestinations}
      />
      <StickyBar
        navigationLinks={getChildNavigationLinks(data.stickyNavigationSection)}
        actionsComponent={
          data.searchPage && (
            <Link
              href={`/${data.searchPage.content?.general?.slug}`}
              internal
              title={t("SEARCH_AND_BOOK")}
              className="cta cta--icon"
            >
              <Icon name="ui-search" />
              <Trans>SEARCH_AND_BOOK</Trans>
            </Link>
          )
        }
      />
      <Hero
        type="compact"
        title={generalPageContent?.title ?? undefined}
        titlePrefix={generalPageContent?.titlePrefix ?? undefined}
        imageSrc={generalPageContent?.headerImage?.url ?? undefined}
      />
      <Breadcrumb
        items={[
          ...compact(
            pageContext.parentIds?.map((id) => {
              const breadcrumbNode = data.breadcrumb.nodes?.find((node) => node.id === id);
              if (breadcrumbNode?.templateName === "Homepage") {
                return {
                  id: breadcrumbNode.id ?? "",
                  title: "Home",
                  url: "/",
                };
              } else if (breadcrumbNode?.templateName === "Page") {
                const nodeContent = (breadcrumbNode as TideItemForPage).content;
                return {
                  id: breadcrumbNode.id ?? "",
                  title:
                    (isEmpty(nodeContent?.general?.title)
                      ? breadcrumbNode.name
                      : nodeContent?.general?.title) ?? "",
                  url:
                    (isEmpty(nodeContent?.general?.slug)
                      ? kebabCase(breadcrumbNode.name)
                      : nodeContent?.general?.slug) ?? "",
                };
              }
              return null;
            })
          ),
          {
            id: pageContext.id,
            title:
              (isEmpty(data.page.content?.general?.title)
                ? data.page.name
                : data.page.content?.general?.title) ?? "",
            url:
              (isEmpty(data.page.content?.general?.slug)
                ? kebabCase(data.page.name)
                : data.page.content?.general?.slug) ?? "",
          },
        ]}
      />
      <PageComponents componentItems={compact(data.page.childItems)} />
      <Footer
        footerNavigationSection={data.footerNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        address={data.website?.content?.contact?.address ?? ""}
        phone={data.website?.content?.contact?.phone ?? ""}
        email={data.website?.content?.contact?.email ?? ""}
        uspGroup={data.footerUspGroup}
        newsletterForm={data.newsletterForm}
      />
    </Layout>
  );
};

interface PageData extends LookupQueryData {
  searchPage: TideItemForSearchPage;
  page: TideItemForPage;
  breadcrumb: TideItemConnection;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  destinations: TideItemForGeographicalRegionConnection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  stickyNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
  footerNavigationSection: TideItemForNavigationSection;
  footerUspGroup: TideItemForUspGroup;
  newsletterForm: TideItemForNewsletterForm;
}

export const query = graphql`
  query PageQuery($id: String, $parentIds: [String] = [], $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    searchPage: tideItemForSearchPage(language: { eq: $language }) {
      id
      content {
        general {
          slug
        }
      }
    }
    page: tideItemForPage(id: { eq: $id }) {
      id
      content {
        general {
          title
          titlePrefix
          headerImage {
            url
          }
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
      }
      ...pageComponentsFragment
    }
    breadcrumb: allTideItem(filter: { id: { in: $parentIds } }) {
      nodes {
        id
        name
        ... on TideItemForPage {
          templateName
          content {
            general {
              title
              slug
            }
          }
        }
        ... on TideItemForHomepage {
          templateName
          content {
            general {
              title
            }
          }
        }
      }
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
          email
          address
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    contactNavigationSection: tideItemForNavigationSection(
      name: { eq: "Contact Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    stickyNavigationSection: tideItemForNavigationSection(
      name: { eq: "Sticky Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    footerNavigationSection: tideItemForNavigationSection(
      name: { eq: "Footer Navigation" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    footerUspGroup: tideItemForUspGroup(name: { eq: "Footer Usps" }, language: { eq: $language }) {
      ...uspGroupFields
    }
    newsletterForm: tideItemForNewsletterForm(
      name: { eq: "Newsletter" }
      language: { eq: $language }
    ) {
      content {
        externalMail {
          templateConfirmationMail {
            tideId
          }
        }
        contactDetails {
          tag {
            tideId
          }
        }
        form {
          privacyPage
        }
      }
    }
  }
`;

export default PageTemplate;
